import type { IPropList } from '@/components/materialTable/src/config/tableConfig'

import { languageMap } from '../../config/languageMap'

type HeaderT = ({
  temp: string
  require?: boolean
} & IPropList)[]
const headerList: HeaderT = [
  {
    prop: 'select',
    selectProp: 'isSelected',
    headSlotName: 'headSelect',
    label: languageMap.get('选择'),
    widthType: 'width',
    width: '64px',
    minWidth: '64px',
    maxWidth: '150px',
    temp: '选择',
  },
  {
    prop: 'plankID',
    headSlotName: 'headPlankID',
    label: languageMap.get('板号'),
    widthType: 'width',
    width: '86px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '1',
  },
  {
    prop: 'roomName',
    headSlotName: 'headRoomName',
    label: languageMap.get('房间'),
    interactionType: 'input',
    widthType: 'width',
    width: '80px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '主卧',
  },
  {
    prop: 'loc',
    headSlotName: 'headLoc',
    label: languageMap.get('柜体'),
    interactionType: 'input',
    widthType: 'width',
    width: '63px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '柜A',
  },

  {
    prop: 'partName',
    headSlotName: 'headPartName',
    label: languageMap.get('板件名称'),
    interactionType: 'input',
    widthType: 'width',
    width: '159px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
    temp: '主卧_柜A_中立板01',
  },
  {
    prop: 'matCode',
    headSlotName: 'headMatCode',
    label: languageMap.get('材质'),
    interactionType: 'input',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
    verify: true,
    temp: '多层实木',
    require: true,
  },
  {
    prop: 'is_high_gloss_plank',
    headSlotName: 'headHighPlank',
    label: languageMap.get('高光板'),
    interactionType: 'select',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
    verify: true,
    temp: '高光板',
  },
  {
    prop: 'texture',
    headSlotName: 'headTexture',
    label: languageMap.get('颜色'),
    interactionType: 'input',
    widthType: 'width',
    width: '100px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
    verify: true,
    temp: 'T01',
    require: true,
  },
  {
    prop: 'thick',
    headSlotName: 'headThick',
    label: languageMap.get('厚度'),
    interactionType: 'input',
    widthType: 'width',
    width: '78px',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
    temp: '18',
    require: true,
  },
  {
    prop: 'specHeight',
    headSlotName: 'headSpecHeight',
    label: languageMap.get('成品长度'),
    interactionType: 'input',
    widthType: 'width',
    width: '106px',
    minWidth: '100px',
    maxWidth: '150px',
    verify: true,
    temp: '2440',
    require: true,
  },
  {
    prop: 'specWidth',
    headSlotName: 'headSpecWidth',
    label: languageMap.get('成品宽度'),
    interactionType: 'input',
    widthType: 'width',
    width: '126px',
    minWidth: '100px',
    maxWidth: '150px',
    verify: true,
    temp: '1220',
    require: true,
  },
  {
    prop: 'amount',
    headSlotName: 'headAmount',
    label: languageMap.get('数量'),
    interactionType: 'input',
    widthType: 'width',
    width: '78px',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
    temp: '1',
    require: true,
  },
  {
    prop: 'texDir',
    headSlotName: 'headTexDir',
    label: languageMap.get('纹理'),
    widthType: 'width',
    width: '126px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '竖纹',
    require: true,
  },
  {
    prop: 'hsInfo',
    headSlotName: 'headHsInfo',
    label: languageMap.get('孔槽数量'),
    widthType: 'width',
    width: '126px',
    minWidth: '100px',
    maxWidth: '150px',
    temp: '1孔1槽',
  },
  {
    prop: 'frontEdge',
    headSlotName: 'headFrontEdge',
    label: languageMap.get('前封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '70px',
    maxWidth: '150px',
    temp: '0',
  },
  {
    prop: 'backEdge',
    headSlotName: 'headBackEdge',
    label: languageMap.get('后封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '70px',
    maxWidth: '150px',
    temp: '0',
  },
  {
    prop: 'leftEdge',
    headSlotName: 'headLeftEdge',
    label: languageMap.get('左封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '70px',
    maxWidth: '150px',
    temp: '0',
  },
  {
    prop: 'rightEdge',
    headSlotName: 'headRightEdge',
    label: languageMap.get('右封边'),
    interactionType: 'input',
    widthType: 'width',
    width: '70px',
    minWidth: '70px',
    maxWidth: '150px',
    temp: '0',
  },
  {
    prop: 'specialShape',
    headSlotName: 'headSpecialShape',
    label: languageMap.get('异形'),
    widthType: 'width',
    width: '63px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '异形',
  },
  {
    prop: 'type',
    headSlotName: 'headType',
    label: languageMap.get('门板'),
    widthType: 'width',
    width: '63px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '门板',
  },
  {
    prop: 'plankNum',
    headSlotName: 'headPlankNum',
    label: languageMap.get('板件条码'),
    // interactionType: 'input',
    widthType: 'width',
    width: '120px',
    minWidth: '100px',
    maxWidth: '150px',
    temp: '6100200300400',
  },
  {
    prop: 'address',
    headSlotName: 'headAddress',
    label: languageMap.get('项目地址'),
    interactionType: 'input',
    widthType: 'width',
    width: '120px',
    minWidth: '100px',
    maxWidth: '150px',
    temp: '订单一',
  },
  {
    prop: 'orderNo',
    headSlotName: 'headOrderNo',
    label: languageMap.get('订单号'),
    interactionType: 'input',
    widthType: 'width',
    width: '80px',
    minWidth: '70px',
    maxWidth: '150px',
    temp: '211006-35',
  },
  {
    prop: 'customer_name',
    headSlotName: 'headCustomerName',
    interactionType: 'input',
    label: languageMap.get('客户'),
    widthType: 'width',
    width: '63px',
    minWidth: '60px',
    maxWidth: '150px',
    temp: '张总',
  },
  {
    prop: 'plank_remarks',
    headSlotName: 'headPlankRemarks',
    interactionType: 'input',
    label: languageMap.get('板件备注'),
    widthType: 'width',
    width: '200px',
    minWidth: '180px',
    maxWidth: '230px',
    tooltip: true,
    temp: '板件备注',
  },
  {
    prop: 'remark',
    headSlotName: 'headRemark',
    interactionType: 'input',
    label: languageMap.get('房间备注'),
    widthType: 'width',
    width: '200px',
    minWidth: '180px',
    maxWidth: '230px',
    tooltip: true,
    temp: '房间备注',
  },

  {
    prop: 'openDir',
    headSlotName: 'headOpenDir',
    interactionType: 'input',
    label: '开门方向',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '200px',
    tooltip: true,
    temp: '左开',
  },
  {
    prop: 'hingeInfo',
    headSlotName: 'headHingeInfo',
    label: languageMap.get('铰链数据'),
    widthType: 'width',
    width: '200px',
    minWidth: '180px',
    maxWidth: '230px',
    tooltip: true,
    temp: '距上100',
  },
]

export { headerList }
