import { PartType } from '@/partTypes'
import { translate } from '@/util/commonFun'

/**
 * @description 处理料单单行上显示铰链的孔位信息文案
 * @param part 板件信息
 */
export function showMaterialHingeinfo(part: PartType) {
  if (!part) return ''
  const {
    hingeInfo,
    openDir,
    oRect: { width, height },
  } = part
  // 判断方向
  if (!hingeInfo?.length) return ''
  const isTowardsRightOpen = ['左开', '右开'].includes(openDir)
  // 左右开的情况下 铰链是在左右两边所以用高度减去反之宽度
  const calcNum = isTowardsRightOpen ? height : width
  const nHinges = hingeInfo
    .map((n) => `${(calcNum - n).toFixed(2)}`)
    .sort((a, b) => +a - +b)
  const dirText = isTowardsRightOpen
    ? translate('materialPage.distanceFromTop')
    : translate('materialPage.distanceFromRight')
  return `${dirText}${nHinges.join('/')}`
}
