import { getRawMaterialPlankInfo } from '@/apis/baseMaterial'
import { getProductionNcSetting } from '@/apis/equipment'
import { getAllKnifes } from '@/apis/paiban'
import { translateLang } from '@/data/i18n/translateLang'
import { IBigPlank, PartType } from '@/partTypes'
import store from '@/store'
import { PreferencesSetting } from '@/store/modules/preferencesSetting/types'
import { changeLayoutData, layoutStart } from '@/util/LayoutFuncs'
import { translate } from '@/util/commonFun'
import { compare } from '@/util/commonFuncs'
import {
  dealLayoutResultData,
  genPaibanRequestParams,
  getPlateKnifeDiameter,
} from '@/util/dealPaibanData'
import { calcBigpartArea } from '@/util/drawPlankFuncs'
import {
  basisSettingExpandPlank,
  dealMorePlateKnife,
} from '@/util/plankCommonFuncs'
import { getMatCodeByHighPlankProp } from '@/util/plankCommonFuncs'
import { Modal } from 'ant-design-vue'
import Vue from 'vue'

/**
 *  计算匹配原片使用
 * @param parts 选中的小板
 * @param process_line_id 当前生产线
 * @param errCallback 错误的回调函数
 * @returns
 */
export async function calcBaseMaterial(
  parts: any[],
  selectStandardPlank: any,
  process_line_id: number,
  errCallback: (errType?: string, errText?: string) => any
) {
  const from = {}
  if (window.sessionStorage.getItem('thinkerx_material')) {
    Object.assign(from, { production_from: 'guimen' })
  }
  /** 获取原片数据的接口返回值 */
  const materialRes = await getRawMaterialPlankInfo({
    uid: store.state.userInfo.id,
    ...from,
  })
  // 没有原片数据直接返回
  if (materialRes.status !== 1) {
    // this.isShowLoading = false
    return false
  } else {
    function getDefalutFromList() {
      let plank
      const selectStandardPlankId = selectStandardPlank.standard_plank_id
      // 兼容默认的原片的id为0
      if (selectStandardPlankId || selectStandardPlankId === 0) {
        plank = materialRes.data.defaultPlankInfo.find(
          (p: any) => p.standard_plank_id == selectStandardPlankId
        )
      } else {
        plank = materialRes.data.defaultPlankInfo.find(
          (i: any) => i.default_selected
        )
      }
      return plank
    }
    const newStandardPlank = Array.isArray(materialRes.data.defaultPlankInfo)
      ? getDefalutFromList()
      : materialRes.data.defaultPlankInfo
    if (newStandardPlank) {
      Object.assign(selectStandardPlank, newStandardPlank)
      store.commit('setSelectStandardPlank', selectStandardPlank)
    }
  }
  // 是否是预排版方案，是就直接使用store存在的设置，不是获取新的nc设置
  const ncSettingRes =
    !process_line_id || process_line_id <= 0
      ? { data: store.state.ncSetting, status: 1 }
      : await getProductionNcSetting({
          setting_id: process_line_id,
          ...from,
        })
  // 获取失败直接返回
  if (ncSettingRes.status !== 1) {
    // this.isShowLoading = false
    if (ncSettingRes.data.err_info) {
      errCallback('setting', ncSettingRes.data.err_info)
    } else {
      errCallback('plank', translate('materialPage.noKnife'))
    }
    return
  }
  /** nc设置 */
  const ncSetting = ncSettingRes.data

  if (process_line_id && process_line_id > 0) {
    const allKnifes = await getAllKnifes({
      setting_id: process_line_id,
    })
    store.commit('setAllKnifes', allKnifes.data.data)
  }
  /** 判断是否没错误 */
  const errCB = (errType?: string, errText?: string) => {
    errCallback(errType, errText)
  }
  const noError =
    materialRes.data && judgePlateKnife(parts, ncSetting.plate_knife_map, errCB)
  // 有错直接返回
  if (!noError) {
    // this.isShowLoading = false
    return false
  }
  // 检测五六面钻模板是否太老 老版本直接返回
  if (oldFiveSixXYTip(ncSetting, errCB)) {
    // this.isShowLoading = false
    return false
  }
  /** 所选标准大板修边值 */
  const edge = selectStandardPlank.plankEdgeOff
  // 原片信息
  const { isAutoSelected, specialPlankInfo } = materialRes.data
  /** 原片数据数组 */
  const materialResData = JSON.parse(JSON.stringify(materialRes.data))
  /** 所选标准大板宽度 */
  const plankWidth = selectStandardPlank.plankWidth
  /** 所选大板长度 */
  const plankHeight = selectStandardPlank.plankHeight
  /** 大板允许的排版宽 */
  let useDefaultPlankWidth = plankWidth
  /** 大板允许的排版长 */
  let useDefaultPlankHeight = plankHeight
  // 重新聚类超大板
  const specialPlank: any = {}
  // 特殊大板信息
  specialPlankInfo.forEach((plank: any) => {
    // plank.uniqueId = nanoid()
    const { color, thick, matCode } = plank
    // 获取分材质下料刀的指定刀径大小
    const diameter = +getPlateKnifeDiameter(
      `${matCode}:${thick}:${color}`,
      ncSetting
    )
    let widthTrimEdge
    let heightTrimEdge
    if (plank.trim_edge.toString().includes(',')) {
      // 四边修边顺序是 前,后,左,右
      const trimEdgeArr = plank.trim_edge.toString().split(',')
      widthTrimEdge = Number(trimEdgeArr[2]) + Number(trimEdgeArr[3])
      heightTrimEdge = Number(trimEdgeArr[0]) + Number(trimEdgeArr[1])
    } else {
      widthTrimEdge = Number(plank.trim_edge) * 2
      heightTrimEdge = Number(plank.trim_edge) * 2
    }
    // 切割宽度
    plank.useJudgeWidth = plank.width - widthTrimEdge + diameter
    // 切割长度
    plank.useJudgeHight = plank.height - heightTrimEdge + diameter

    const specialHighPlank = matCode.includes('高光_')
    /** 同typeSymbol */
    const symbol = `${matCode.replace(
      '高光_',
      ''
    )}:${thick}:${color}:${specialHighPlank}`
    plank.symbol = symbol
    if (Reflect.has(specialPlank, symbol)) {
      specialPlank[symbol].push(plank)
    } else {
      specialPlank[symbol] = [plank]
    }
  })

  // 根据宽高最长的重新排序
  Object.keys(specialPlank).forEach((k) => {
    specialPlank[k] = specialPlank[k].sort((a: any, b: any) => {
      const aMax = Math.max(a.width, a.height)
      const bMax = Math.max(b.width, b.height)
      return aMax - bMax
    })
  })
  // 已匹配上的原片
  const needMaterialList: any[] = []
  // 尺寸不能匹配的板件
  const notMatchPartList = []
  for (const part of parts) {
    const { texDir, texture, matCode, thick } = part

    /** 获取扩张距离 */
    const baRes = basisSettingExpandPlank(part, ncSetting)
    let { expandedX, expandedY, diameter } = baRes
    const { contourSizeRecoup, longPlankExpandedSize, finalExtendDisMap } =
      baRes
    diameter = +diameter
    // 计算公式：板件宽度 - 2倍修边 + 刀径 = 板件实际排版宽度
    useDefaultPlankWidth = plankWidth - edge * 2 + diameter
    useDefaultPlankHeight = plankHeight - edge * 2 + diameter
    // 无论异形还是矩形板都当作矩形来进行扩张判断是否超长
    // 判断是否是异形
    if (part.path) {
      expandedX = longPlankExpandedSize
      expandedY = longPlankExpandedSize
    }
    const rect = {
      width:
        part.rect.width + // 板子宽度
        expandedX * 2 + //长短边放量，异形全部是长边放量
        (finalExtendDisMap
          ? Number(finalExtendDisMap.left) + Number(finalExtendDisMap.right)
          : 0) + //拉直器
        contourSizeRecoup * 2 + // 玻璃切割机的处理
        diameter, //刀直径
      height:
        part.rect.height +
        expandedY * 2 +
        (finalExtendDisMap
          ? Number(finalExtendDisMap.top) + Number(finalExtendDisMap.bottom)
          : 0) +
        contourSizeRecoup * 2 +
        diameter,
    }
    part.blankWidth = Math.floor(rect.width * 100) / 100
    part.blankHeight = Math.floor(rect.height * 100) / 100
    part.originWidth = rect.width + edge * 2
    part.originHeight = rect.height + edge * 2

    // 根据板件材质，厚度，颜色，高光板来生成板件的唯一标识 lx
    const highPlank = part.is_high_gloss_plank
    const matCodeTemp = matCode.replace('高光_', '')
    const symbolArr = [
      part.symbol,
      `${matCodeTemp}:${thick}:${texture}:${highPlank}`,
      `${matCodeTemp}::${texture}:${highPlank}`,
      `${matCodeTemp}:${thick}::${highPlank}`,
      `:${thick}:${texture}:${highPlank}`,
      `${matCodeTemp}:::${highPlank}`,
      `::${texture}:${highPlank}`,
      `:${thick}::${highPlank}`,
      `:::${highPlank}`,
    ]
    // 检查当前板件是否已经有匹配的原片,已存在匹配则跳过
    // needMaterial是已经匹配上的原片
    const isExists = needMaterialList.find(
      (plank) =>
        part.symbol === plank.symbol &&
        rect.width <= plank.useJudgeWidth &&
        rect.height <= plank.useJudgeHight
    )
    if (isExists) continue
    // 是否需要再进行更大原片的匹配
    let isNeedMatchBiggerPlank = false
    // 是否有默认选用
    let flag = false
    let defaultPlank
    // 是否符号规则的特殊板材都未默认选用
    const notDefaultPlank = symbolArr
      .map((item) => specialPlank[item])
      .filter((item) => item)
      .flat(1)
      .every((plank) => !plank.isPicked)
    for (let i = 0; i < symbolArr.length; i++) {
      if (specialPlank[symbolArr[i]]) {
        defaultPlank = specialPlank[symbolArr[i]]?.find(
          (plank: any) => plank.isPicked
        )
        // 存在默认选用的板件
        if (defaultPlank) {
          // 长宽匹配则使用并且跳过，不匹配则看是否开启更大板件排版
          if (
            checkPlankIsAvailable(texDir, rect, {
              width: defaultPlank.useJudgeWidth,
              height: defaultPlank.useJudgeHight,
            })
          ) {
            const color = texture
            const newMatCode = getMatCodeByHighPlankProp(highPlank, matCode)
            needMaterialList.push({
              ...defaultPlank,
              matCode: newMatCode,
              // matCode: highPlank ? matCode : matCode.replace('高光_', ''),
              thick,
              color,
            })
            flag = true
            // 存在默认选用的大板，关闭超尺小板只排特殊大板
            materialRes.data.only_layout_too_big = false
            break
          }
        }
      }
    }
    //默认选用未匹配成功则看标准板材
    if (!flag) {
      // 符号规则的特殊板材都未默认选用且标准板材长宽匹配才使用标准板材
      if (
        notDefaultPlank &&
        checkPlankIsAvailable(texDir, rect, {
          width: useDefaultPlankWidth,
          height: useDefaultPlankHeight,
        })
      ) {
        continue
      }
      isNeedMatchBiggerPlank = true
      notMatchPartList.push(part)
    } else {
      continue
    }

    // 当前板件是否有需要使用超长板来排版
    if (!isNeedMatchBiggerPlank || !isAutoSelected || !notDefaultPlank) continue
    // 此处删除上面添加的当前板件
    notMatchPartList.length = notMatchPartList.length - 1
    // 记录是否成功匹配超大板
    let isMatch = false
    for (let i = 0; i < symbolArr.length; i++) {
      if (specialPlank[symbolArr[i]]) {
        for (const plank of specialPlank[symbolArr[i]]) {
          if (
            checkPlankIsAvailable(texDir, rect, {
              width: plank.useJudgeWidth,
              height: plank.useJudgeHight,
            })
          ) {
            const color = texture
            const newMatCode = getMatCodeByHighPlankProp(highPlank, matCode)
            needMaterialList.push({
              ...plank,
              thick,
              color,
              matCode: newMatCode,
              // matCode: highPlank ? matCode : matCode.replace('高光_', ''),
            })
            isMatch = true
            break
          }
        }
      }
      if (isMatch) {
        break
      }
    }

    if (!isMatch) {
      notMatchPartList.push(part)
    }
  }
  const setNotMatchPartList: any[] = []
  notMatchPartList.forEach((item) => {
    if (
      setNotMatchPartList.findIndex(
        (setItem) =>
          item.plankID === setItem.plankID &&
          item.partName === setItem.partName &&
          item.ggid === setItem.ggid
      ) === -1
    ) {
      setNotMatchPartList.push(item)
    }
  })
  return {
    setNotMatchPartList,
    autoAddSpecialPlate: materialRes.data.auto_add_special_plate,
    materialRes: materialResData,
    lackBaseMaterials: notMatchPartList,
    needBaseMaterials: [...new Set(needMaterialList)],
  }
}

/**
 * 是否刀具不符合规则
 * @param parts 小板数据
 * @param plate_knife_map 刀具库列表为一个对象
 * 数据格式{22:3:0:{
 *  diameter:6,
 *  error:"",
 *  id:108,
 *  knife:"default",
 *  matCode:"22",
 *  thick:23
 * }}
 * @param errCallback 错误回调
 * @returns boolean 是否符合规则
 */
export function judgePlateKnife(
  parts: any[],
  plate_knife_map: any,
  errCallback?: (errType?: string, errText?: string) => any
) {
  // 没有分材质下料刀直接返回true
  if (!plate_knife_map) return true
  /** 开料清单已选板件或者预选板件 */
  const selectPlanks = parts
  /** 处理板件列表 */
  const planksArr: any[] = []
  selectPlanks.forEach((item) => {
    // typeSymbol是由 matCode:thick:texture:isHighGlass 组成的
    const symbolArr = String(item.typeSymbol).split(':')
    planksArr.push(
      // 但是刀具匹配只需要matCode:thick
      symbolArr.slice(0, -2).join(':')
    )
  })
  /** 刀具列表的key值数组 由matCode:thick组成 样例：多层实木:18.0 */
  let knifesArr = Object.keys(plate_knife_map)
  knifesArr = knifesArr.map((item) => {
    // 如果小数部分为0，则取整数
    if (!Number(item.split('.')[1])) {
      item = item.split('.')[0]
    }
    return item
  })
  if (!intersection(knifesArr, planksArr)) return true
  let noError = true
  for (const key in plate_knife_map) {
    if (plate_knife_map[key].error) {
      noError = false
      errCallback && errCallback('plank', plate_knife_map[key].error)
      break
    }
  }
  return noError
}

export function dealDataForPaiban(
  plankList: any[],
  ncSetting: any,
  otherSetting: {
    isSpecialShape: boolean
    isNewLayout: boolean
    baseMaterialList: any[]
    surplusObj?: any
    isOnlyLayoutTooBig?: boolean
  }
) {
  // 多材质不同下料刀
  const moreMatCodePaibanData = dealMorePlateKnife(plankList, ncSetting)

  // prePaibanData进行push操作
  store.commit('setIsSinglePushPrePaibanData', true)
  // 清空perPaibanData
  store.commit('setPrePaibanData', [])
  const fnialList: any[] = []
  // 不同开料刀生成不同的请求数据
  moreMatCodePaibanData.forEach((paibanData) => {
    const {
      preLayoutData,
      setting: { diameter },
    } = paibanData
    const nc = JSON.parse(JSON.stringify(ncSetting))
    nc.knife.diameter = diameter
    changeLayoutData(preLayoutData)
    // 进行排版前的板件处理
    const val = layoutStart(nc)

    fnialList.push(val)
  })
  store.commit('setIsSinglePushPrePaibanData', false)
  if (store.state.errorPathPlankID.length) {
    Vue.prototype.$message({
      type: 'error',
      message: `板件内部存在异常数据（板号：${store.state.errorPathPlankID}），请检查！`,
    })
    return null
  }
  if (fnialList.some((it) => !it) || !fnialList.length) {
    Vue.prototype.$message({
      type: 'error',
      message:
        '<span id="material_knife_error">未设置下料刀直径, 请前往雕刻机设置刀具!</span>',
      dangerouslyUseHTMLString: true,
    })
    return null
  }
  if (fnialList.length) {
    const newList = fnialList.filter((i) => i)
    const slotErrList = newList.filter((i) => {
      return i && i.layout?.isErr
    })

    if (slotErrList.length) {
      Modal.error({
        title: translateLang('提示'),
        content: slotErrList[0] ? slotErrList[0].layout?.errMsg : '',
        centered: true,
      })
      return null
    }
  }
  const resultArr = []
  let hasErr = false
  for (const val of fnialList) {
    /** 处理上面layoutStart函数所返回的数据用于排版 */
    const data = dealLayoutResultData(
      val,
      otherSetting.baseMaterialList,
      {
        isSpecialShape: otherSetting.isSpecialShape,
        customPlankOrder: store.state.customPlankOrder,
        plankOrderList: store.state.plankOrderList,
      },
      ncSetting,
      store,
      otherSetting.isNewLayout,
      otherSetting.isOnlyLayoutTooBig
    )
    if (!data) {
      hasErr = true
      break
    }
    resultArr.push(data)
  }
  if (hasErr) {
    return null
  }
  let surplusObj = {}
  // 获取余料数据
  if (otherSetting.surplusObj) {
    // 处理余料数据 准备排版数据
    surplusObj = otherSetting.surplusObj
  }
  /** 参数列表 */
  const paramObjArr: any[] = []
  resultArr.forEach(({ result }) => {
    /** 生成排版接口所需参数 */
    const paramObj = genPaibanRequestParams(store, surplusObj, result)
    paramObjArr.push(paramObj)
  })
  return paramObjArr
}

/** 判断两个数组的包含关系 */
export function intersection(arr1: any[], arr2: any[]) {
  return Boolean(arr1.filter((i) => arr2.includes(i)).length)
}

/**
 * 旧版五六面钻设置提示
 * @param res 获取的ncSetting接口返回值
 * @param errCallback 错误的回调
 * @returns 返回boolean值
 */
export function oldFiveSixXYTip(res: any, errCallback: () => any) {
  // 五六面钻设置过老提示 (为啥是499，不明)
  if (res.status == 499) {
    const h = Vue.prototype.$createElement
    Vue.prototype
      .$msgbox({
        title: '警告',
        message: h('p', null, [
          h('i', { style: 'color: red' }, '五六面钻设置过老'),
          h('span', null, '，请联系售后人员重新对接'),
        ]),
        confirmButtonText: '确定',
      })
      .catch((e: any) => {
        throw e
      })
    errCallback && errCallback()
    return true
  } else {
    return false
  }
}

/**
 * 检查大板是否可用
 * @param texDir 小板纹理
 * @param partInfo 小板数据
 * @param plankInfo 大板数据
 * @returns 返回boolean值
 */
export function checkPlankIsAvailable(
  texDir: string,
  partInfo: any,
  plankInfo: any
) {
  // 横竖纹的判断 小板宽小于等于大板宽、小板长小于等于大板长
  if (texDir !== 'notcare') {
    return (
      partInfo.width <= plankInfo.width && partInfo.height <= plankInfo.height
    )
  } else {
    // 无纹理的判断 即小板矩形短边小于等于大板短边、小板长边小于等于大板长边同时满足
    const [partMin, partMax] = [partInfo.width, partInfo.height].sort(
      (a, b) => a - b
    )
    const [plankMin, plankMax] = [plankInfo.width, plankInfo.height].sort(
      (a, b) => a - b
    )
    return partMin <= plankMin && partMax <= plankMax
  }
}

export async function dealStandardPlank(
  selectStandardPlank: any,
  params: { uid: number; from?: 'guimen' }
) {
  if (selectStandardPlank) {
    return { ...selectStandardPlank, plankEdgeOff: selectStandardPlank.margin }
  } else {
    const res = await getRawMaterialPlankInfo(params)
    let defaultStandardPlank = null
    if (res.status) {
      defaultStandardPlank = Array.isArray(res.data.defaultPlankInfo)
        ? res.data.defaultPlankInfo.find((plank: any) => plank.default_selected)
        : res.data.defaultPlankInfo
    }
    return defaultStandardPlank
  }
}

/** 组装排版页面需要使用的 finalDrawData 数据 */
/** 其他分支有写公共文件的判断方法，到时候上线时替换 */
/**
 * @description 是否是余料大板，也可以用于判断板件是否在一个余料大板上
 * @param plank 板件
 * @returns
 */
function isSurplusBigPlank(
  plank: Partial<
    Pick<PartType & IBigPlank, 'surplusInfo' | 'specialType' | 'otherPlate'>
  >
) {
  // 判断逻辑: surplusInfo有值且surplusInfo.other_plate为 false
  return Boolean(
    ((Reflect.has(plank, 'surplusInfo') &&
      Object.keys(plank?.surplusInfo ?? {}).length) ||
      (Reflect.has(plank, 'specialType') && plank.specialType === 'surplus')) &&
      !plank.surplusInfo?.other_plate
  )
}
/**
 * @description 是否是余料小板
 * @param plank 板件
 * @returns
 */
function isSurplusPlank(
  plank: Partial<
    Pick<PartType & IBigPlank, 'surplusInfo' | 'specialType' | 'otherPlate'>
  >
) {
  return Boolean(
    Reflect.has(plank, 'specialType') && plank.specialType === 'surplus'
  )
}

/**
 * @description 检查是否是特殊大板
 * @param bigPlank 大板数据
 * @param isStrictCheck 是否严格检查是否是特殊大板(特殊情况下不需要严格检查)
 * @returns
 */
function isSpecialBigPlank(
  bigPlank: Partial<Pick<IBigPlank, 'otherPlate' | 'surplusInfo'>>,
  isStrictCheck = true
) {
  // 判断逻辑: otherPlate有值且surplusInfo中other_plate是 true 或没有值才是特殊大板
  return (
    Reflect.has(bigPlank, 'otherPlate') &&
    Object.keys(bigPlank?.otherPlate ?? {}).length &&
    (!isStrictCheck || bigPlank.surplusInfo?.other_plate)
  )
}

/**
 * @description 检查是否是单面板
 * @param plank 大板
 * @returns
 */
function isSingleFacePlank(plank: { ableToggle: boolean }) {
  return !plank.ableToggle
}

const plankOrders = [
  { type: 'surplus' }, // 余料
  { type: 'normal' }, // 普通板
  { type: 'big_normal' }, // 普通特殊大板
] as const
type IPlankOrderKey = typeof plankOrders[number]['type']
/**
 * @description 按照设定的优先级进行排序
 * @param paibanData 排版处理好的每个大板信息
 */
export function dealPaibanDataByOrder(paibanData: IBigPlank[]) {
  const plankTypeMap = plankOrders.reduce((map, { type }) => {
    map.set(type, [])
    return map
  }, new Map<IPlankOrderKey, IBigPlank[]>())
  paibanData.forEach((bigPlank) => {
    let key: IPlankOrderKey = 'normal'
    if (isSurplusBigPlank(bigPlank)) {
      key = 'surplus'
    } else if (isSpecialBigPlank(bigPlank)) {
      key = 'big_normal'
    }
    plankTypeMap.get(key)!.push(bigPlank)
  })
  return Array.from(plankTypeMap.values())
}

/**
 *@description 计算是否是单双面板并分离单双面大板
 */
export function separationSingleAndDouble(
  paibanData: IBigPlank[],
  nc: ncSetting
) {
  return paibanData.reduce(
    (pre, cur) => {
      cur.ableToggle = isNeedFanByBigPlank(cur, nc)
      pre[isSingleFacePlank(cur) ? 1 : 0].push(cur)
      return pre
    },
    [[], []] as [IBigPlank[], IBigPlank[]]
  )
}

/**
 * @description 计算大板数组优化率并按照优化率进行降序排序
 * @param paibanData 大板数据
 * @param nc
 * @param isUnuseSurplus 是否计算余料优化率
 * @returns
 */
export function sortPlankDataByOptimizationRate(
  paibanData: IBigPlank[],
  nc: ncSetting,
  isUnuseSurplus: boolean
) {
  paibanData.forEach((bigPlank) => {
    const { usedArea, usedRate } = calcBigpartArea(bigPlank, nc, isUnuseSurplus)
    bigPlank.usedArea = usedArea
    bigPlank.usedRate = usedRate > 1 ? 1 : usedRate
  })
  return paibanData.sort((a, b) => b.usedRate - a.usedRate)
}

/** */
export function genFinalDataOrder(
  paibanData: IBigPlank[],
  nc: ncSetting,
  preference: PreferencesSetting,
  isForcedReset = false
) {
  // 对于不曾排序排序的数据才排序，排序后的数据按照原有的顺序进行拆分即可
  // _sortNum的作用就是让这里认为板件是否排过序，如果想要重新排序可以删除_sortNum再传入
  if (!paibanData.some((it) => Reflect.has(it, '_sortNum')) || isForcedReset) {
    paibanData = sortPlankDataByOptimizationRate(
      paibanData,
      nc,
      preference.isSurplusNoRoll
    )
    // 按照顺序拆分并全部分离成单双面板
    paibanData = dealPaibanDataByOrder(paibanData).reduce((acc, cur) => {
      acc.push(...separationSingleAndDouble(cur, nc).flat())
      return acc
    }, [] as IBigPlank[])
  }

  const drawMap = {
    normal: new Map(),
    otherPlate: new Map(),
  }
  let totalUsedRate = 0
  let totalUsedArea = 0
  paibanData.forEach((bigPlank, idx) => {
    totalUsedRate += bigPlank.usedRate
    totalUsedArea += bigPlank.usedArea
    const isSpecial = isSpecialBigPlank(bigPlank, false)
    const curMap = isSpecial ? drawMap.otherPlate : drawMap.normal
    const title = `${bigPlank.texture}:${bigPlank.matCode}:${bigPlank.thick}${
      isSpecial
        ? `:${bigPlank.otherPlate.width}:${bigPlank.otherPlate.height}`
        : ''
    }`
    bigPlank._sortNum = idx
    bigPlank.sideType = 1
    const {
      matCode,
      thick,
      texture,
      usedArea,
      usedRate,
      otherPlate,
      surplusInfo,
      ableToggle,
      parts,
    } = bigPlank

    const obj =
      curMap.get(title) ??
      curMap
        .set(title, {
          title,
          matCode,
          thick,
          texture,
          data: [],
          fanbanCount: 0,
          counts: 0,
          usedRate: 0,
          usedArea: 0,
          otherPlate,
          type: surplusInfo ? 'surplus' : '',
          otherPlateW: isSpecial ? otherPlate?.width : undefined,
        })
        .get(title)
    obj.isManual = parts.some((v) => v.isManual)
    obj.hasDoor = parts.some((v) => v.type == 'SingleDoor')
    obj.counts += 1
    obj.usedRate += usedRate
    obj.usedArea += usedArea

    obj.fanbanCount += ableToggle ? 1 : 0
    obj.data.push(bigPlank)
  })
  const normalResult = Array.from(drawMap.normal.values())
  normalResult.sort(compare('thick', 'down') as any)
  const otherPlate = Array.from(drawMap.otherPlate.values())
  otherPlate.sort(compare('thick', 'down', 'otherPlateW') as any)
  return {
    totalUsedArea,
    totalUsedRate,
    data: [...normalResult, ...otherPlate],
  }
}

/**
 * @description 传入大板数据是否支持翻版
 * @param bigPlank 大板数据
 */
function isNeedFanByBigPlank(bigPlank: IBigPlank, ncSetting: ncSetting) {
  let toggleFlag = true // true 是不可以翻 false 是可以翻
  let isThroughHole = false
  let isThroughSlot = false
  /** 是否是双面板 */
  let isRollPlank = false

  /** 是否仅计算雕刻机加工的孔槽翻版 */
  const isCalTrunNumAFTshunt = ncSetting.cal_turn_num_aft_shunt
  /** 是否是组合设备 */
  const combinationEquip = ncSetting.combinationEquip
  const onlyHoleSlotArr = bigPlank.parts
    .filter((p) => p.specialType !== 'surplus')
    .map((part) => [part.slots, part.holes, part.handleSlopes ?? []])
    .flat(2)
  if (isCalTrunNumAFTshunt && !combinationEquip) {
    /** 是否异形孔数据在雕刻机上加工 */
    const isEngravingCurveholes = bigPlank.parts.every(
      (part) => part.process_in_engraving_to_curve
    )

    /** 所有分流的孔槽 */
    const shuntHoleSlotArr = onlyHoleSlotArr.filter(
      (it) => it.process_in_engraving || it.process_in_engraving_to_turn
    )
    /** 所有的curveHoles */
    const shuntCurveHoles = bigPlank.parts
      .filter((p) => p.specialType !== 'surplus')
      .map((part) => part.curveHoles ?? [])
      .flat(1)
    /** 是否有在雕刻机上反面加工的孔槽 */
    const hasEngravingBackProcess =
      shuntHoleSlotArr.some((it) => it.side == -1 && it.process_in_engraving) ||
      (isEngravingCurveholes && shuntCurveHoles.some((hole) => hole.side == -1))
    // bigPlank.ableToggle = hasEngravingBackProcess
    isRollPlank = hasEngravingBackProcess
    const hasBackHoleSlots = [...onlyHoleSlotArr, ...shuntCurveHoles].some(
      (it) => it.side == -1
    )
    if (!hasEngravingBackProcess && ncSetting.ncHoleSlot != 'doubleHoleSlot') {
      isRollPlank = false
      Object.assign(bigPlank, {
        noEngraving: hasBackHoleSlots, // 用于标识是否需要绘制“雕“字
      })
    } else {
      isRollPlank = hasEngravingBackProcess
      Object.assign(bigPlank, {
        noEngraving: hasEngravingBackProcess ? false : hasBackHoleSlots, // 用于标识是否需要绘制“雕“字
        ableToggle: isRollPlank,
      })
    }

    return isRollPlank
  }

  for (const part of bigPlank.parts) {
    if (isSurplusPlank(part)) continue
    const {
      holes = [],
      slots = [],
      curveHoles = [],
      millInfo = [],
      handleSlopes = [],
    } = part
    const colletArr = [
      ...(slots ?? []),
      ...(handleSlopes ?? []),
      ...(millInfo ?? []),
    ]
    const allHoleSlotArr = [...colletArr, ...curveHoles, ...holes]

    // 为 false 就需要翻版
    if (toggleFlag) {
      toggleFlag = allHoleSlotArr.every((v) => v.side == 1)
    }
    if (!isThroughHole) {
      isThroughHole = holes.some(
        (v: any) => v.deep * 1 + 0.001 >= bigPlank.thick
      )
    }
    if (!isThroughSlot) {
      isThroughSlot = colletArr.some(
        (v: any) => v.deep * 1 + 0.001 >= bigPlank.thick
      )
    }
    // 判断了通孔/通槽分双面打穿
    const isNeedToggle =
      !toggleFlag ||
      (ncSetting.throughTowSideToCut && isThroughHole) ||
      (ncSetting.through_slot_two_side_cut && isThroughSlot)

    // 记录是否可以切换正反
    // bigPlank.ableToggle = isNeedToggle
    isRollPlank = isNeedToggle

    // // 条件全部满足跳出循环
    // if (!toggleFlag && isThroughHole && isThroughSlot) break
  }

  return isRollPlank
}
